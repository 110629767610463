nav {
  /* max-height: 10vh; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 99999;
  background-color: #33548b;
  flex-wrap: wrap;
}
.navigation {
  background-color: #33548b;
}
.logo {
  margin-left: 5vw;
}
.logo img {
  width: 150px;
}

.nav-links {
  margin-right: 10vw;
}
.nav-links ul {
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  flex-wrap: wrap;
}
.nav-links ul li {
  list-style: none;
  position: relative;

  margin: 0 20px;
}

.nav-links ul li a {
  display: block;
  padding: 20px 20px 20px 16px;
}

.nav-links ul li:hover {
  background-color: #244680;
  transition: all 0.2s ease;
}

.bars {
  cursor: pointer;
  display: none;
}
.backgroundNav {
  width: 0;
}

@media screen and (max-width: 992px) {
  .nav-links {
    margin-right: 0;
  }
  .nav-links ul li {
    margin: 0;
  }
}

@media only screen and (max-width: 768px) {
  .backgroundNav {
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 9999;
    overflow: hidden;
    pointer-events: none;
  }
  .backgroundNav.active {
    opacity: 1;
    height: 100vh;
    transition: opacity 0.2s ease;
    pointer-events: all;
  }
  nav {
    align-items: flex-start;
  }
  .nav-links {
    overflow: hidden;
    position: absolute;
    top: -1000px;
    opacity: 0;
    pointer-events: none;
  }

  .nav-links.active {
    background-color: #222222;
    display: flex;
    padding: 0;
    margin: 0;
    position: relative;
    top: -20vh;
    right: 0;
    height: 110vh;
    z-index: 99999;
    width: 100%;
    justify-content: center;
    opacity: 1;
    pointer-events: all;
    transition: opacity 0.2s ease;
  }

  .nav-links.active ul {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 99999;
  }

  .nav-links.active ul li {
    padding: 10px 0;
    width: 100%;
    text-align: center;
    border-top: 1px solid rgba(255, 255, 255, 0.05);
    margin: 0;
    z-index: 99999;
    transition: padding 0.5s ease;
  }
  .nav-links ul li a {
    padding: 30px 0;
  }
  .nav-links.active ul li a:hover {
    background: none;
  }
  .nav-links.active ul li:hover {
    transition: 0.2s ease;
    background-color: #333333;
  }
  .bars {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    margin: 25px;
    z-index: 99999;
  }
  .bar-1,
  .bar-2,
  .bar-3 {
    display: block;
    height: 2px;
    width: 30px;
    margin: 7px;
    background-color: #fff;
    transition: all 0.3s ease-in-out;
  }
  .bars.active .bar-1 {
    transform: translate(4px, 9px) rotate(-45deg);
  }
  .bars.active .bar-2 {
    opacity: 0;
  }
  .bars.active .bar-3 {
    transform: translate(3px, -8px) rotate(45deg);
  }
}
/* @media screen and (min-width: 2159px) {
  nav {
    padding: 0 12.5vw;
  }
  .logo {
    margin-left: 0;
  }
  .nav-links {
    margin-right: 0;
    width: 80%;
  }
  .nav-links ul {
    justify-content: flex-end;
  }
} */

/* @media (max-width: 840px) and (orientation: landscape) {
  nav {
    min-height: 20vh;
  }
  .nav-links.active ul li {
    padding: 3px 0;
  }
  .logo {
    margin-left: 5vw;
  }
  .logo img {
    height: 75px;
  }
  .bars {
    margin-top: 0;
    top: 18px;
  }
  .nav-links.active {
    position: absolute;
    height: 106vh;
    top: -6vh;
    right: 0;
    overflow: hidden;
  }
  .nav-links ul li a {
    display: block;
    padding: 15px;
  }
} */

@media screen and (max-width: 540px) {
  .logo img {
    height: 75px;
  }
}
@media screen and (max-width: 480px) {
  nav {
    max-height: 12vh;
  }
  .logo {
    margin-left: 2vw;
  }

  .nav-links.active {
    width: 100%;
    position: absolute;
    height: 107vh;
    top: -7vh;
    right: 0;
    overflow: hidden;
  }

  .bars {
    margin-top: 0;
    top: 20px;
  }
}

/* @media screen and (max-width: 480px) and (orientation: landscape) {
  .logo img {
    width: 100px;
    margin-left: 20px;
  }
} */
@media screen and (max-width: 320px) {
  nav {
    max-height: 15vh;
  }
}
@media screen and (min-width: 2159px) {
  nav {
    max-width: 1197px;
    padding: 0;
    margin: 0 auto;
  }
}
